<template>
    <div>
        <el-dialog :title="operation ? '修改' : '查看'"
                   :visible.sync="interviewVisible"
                   v-model="interviewVisible"
                   :close-on-click-modal="false"
                   width="60%">
            <el-form label-width="100px"
                     :rules="rules"
                     ref="InterviewForm"
                     :model="InterviewForm"
                     class="demo-form-inline">
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="面试公司:" prop="Factory">
                            <el-select v-model="InterviewForm.Factory"
                                       @change="selectModel($event)"
                                       filterable
                                       placeholder="面试公司"
                                       style="width: 100%">
                                <el-option v-for="item in OrganizeLists"
                                           :key="item.ID"
                                           :label="item.label"
                                           :value="item.Name"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="面试岗位:" prop="Post">
                            <el-input v-model="InterviewForm.Post"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="岗位类型:" prop="PostType">
                            <el-select v-model="InterviewForm.PostType"
                                       placeholder="岗位类型"
                                       style="width: 100%">
                                <el-option v-for="item in positionLists"
                                           :key="item.value"
                                           :label="item.label"
                                           :value="item.value"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row>
                    <el-col :span="12">
                        <el-form-item prop="TalkDate" label="面试时间">
                            <el-date-picker type="date"
                                            v-model="InterviewForm.TalkDate"
                                            value-format="yyyy-MM-dd"
                                            placeholder="选择日期"></el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="面试人员:" prop="TalkPersons">
                            <el-input type="text"
                                      :disabled="true"
                                      v-model="InterviewForm.TalkPersons">
                                <!-- <el-button slot="append" icon="el-icon-search" @click="chooseUser"></el-button> -->
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row>
                    <el-col :span="12">
                        <el-form-item label="基础能力:" prop="BasicAbility">
                            <el-input placeholder="请输入内容"
                                      v-model="InterviewForm.BasicAbilityExp"
                                      class="input-with-select">
                                <el-select v-model="InterviewForm.BasicAbility"
                                           slot="prepend"
                                           style="width: 80px"
                                           placeholder="选择">
                                    <el-option v-for="item in BasicLists"
                                               :key="item.value"
                                               :label="item.label"
                                               :value="item.value"></el-option>
                                </el-select>
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="专业知识技能:" prop="ProfessionalAbility">
                            <el-input placeholder="请输入内容"
                                      v-model="InterviewForm.ProfessionalAbilityExp"
                                      class="input-with-select">
                                <el-select v-model="InterviewForm.ProfessionalAbility"
                                           slot="prepend"
                                           style="width: 80px"
                                           placeholder="选择">
                                    <el-option v-for="item in BasicLists"
                                               :key="item.value"
                                               :label="item.label"
                                               :value="item.value"></el-option>
                                </el-select>
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="相关工作经验:" prop="WorkAbility">
                            <el-input placeholder="请输入内容"
                                      v-model="InterviewForm.WorkAbilityExp"
                                      class="input-with-select">
                                <el-select v-model="InterviewForm.WorkAbility"
                                           slot="prepend"
                                           style="width: 80px"
                                           placeholder="选择">
                                    <el-option v-for="item in BasicLists"
                                               :key="item.value"
                                               :label="item.label"
                                               :value="item.value"></el-option>
                                </el-select>
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="办公软件使用能力:" prop="OfficeAbility">
                            <el-input placeholder="请输入内容"
                                      v-model="InterviewForm.OfficeAbilityExp"
                                      class="input-with-select">
                                <el-select v-model="InterviewForm.OfficeAbility"
                                           slot="prepend"
                                           style="width: 80px"
                                           placeholder="选择">
                                    <el-option v-for="item in BasicLists"
                                               :key="item.value"
                                               :label="item.label"
                                               :value="item.value"></el-option>
                                </el-select>
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="沟通能力表达:" prop="CommunicateAbility">
                            <el-input placeholder="请输入内容"
                                      v-model="InterviewForm.CommunicateAbilityExp"
                                      class="input-with-select">
                                <el-select v-model="InterviewForm.CommunicateAbility"
                                           slot="prepend"
                                           style="width: 80px"
                                           placeholder="选择">
                                    <el-option v-for="item in BasicLists"
                                               :key="item.value"
                                               :label="item.label"
                                               :value="item.value"></el-option>
                                </el-select>
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="专业软件使用能力:" prop="ProfessionalSoftware">
                            <el-input placeholder="请输入内容"
                                      v-model="InterviewForm.ProfessionalSoftwareExp"
                                      class="input-with-select">
                                <el-select v-model="InterviewForm.ProfessionalSoftware"
                                           slot="prepend"
                                           style="width: 80px"
                                           placeholder="选择">
                                    <el-option v-for="item in BasicLists"
                                               :key="item.value"
                                               :label="item.label"
                                               :value="item.value"></el-option>
                                </el-select>
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="发展潜力:" prop="PotentialAbilityExp">
                            <el-input placeholder="请输入内容"
                                      v-model="InterviewForm.PotentialAbility"
                                      class="input-with-select">
                                <el-select v-model="InterviewForm.PotentialAbility"
                                           slot="prepend"
                                           style="width: 80px"
                                           placeholder="选择">
                                    <el-option v-for="item in BasicLists"
                                               :key="item.value"
                                               :label="item.label"
                                               :value="item.value"></el-option>
                                </el-select>
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="仪容仪表:" prop="DressUpExp">
                            <el-input placeholder="请输入内容"
                                      v-model="InterviewForm.DressUpExp"
                                      class="input-with-select">
                                <el-select v-model="InterviewForm.DressUp"
                                           slot="prepend"
                                           style="width: 80px"
                                           placeholder="选择">
                                    <el-option v-for="item in BasicLists"
                                               :key="item.value"
                                               :label="item.label"
                                               :value="item.value"></el-option>
                                </el-select>
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="价值观/态度:" prop="AttitudeExp">
                            <el-input placeholder="请输入内容"
                                      v-model="InterviewForm.AttitudeExp"
                                      class="input-with-select">
                                <el-select v-model="InterviewForm.Attitude"
                                           slot="prepend"
                                           style="width: 80px"
                                           placeholder="选择">
                                    <el-option v-for="item in BasicLists"
                                               :key="item.value"
                                               :label="item.label"
                                               :value="item.value"></el-option>
                                </el-select>
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="其他:">
                            <el-input placeholder="请输入内容"
                                      v-model="InterviewForm.Remark"
                                      class="input-with-select">
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="整体匹配度:">
                            <el-select v-model="InterviewForm.MatchRate" placeholder="整体匹配度" style="width: 100%">
                                <el-option v-for="item in matchRateLists" :key="item.value" :label="item.label" :value="item.value"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="建议:">
                            <el-select v-model="InterviewForm.Recommendation" placeholder="建议" style="width: 100%">
                                <el-option v-for="item in RecommendationLists" :key="item.value" :label="item.label" :value="item.value"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="面试结果:" prop="IsPassed">
                            <el-select v-model="InterviewForm.IsPassed" placeholder="面试结果" style="width: 100%">
                                <el-option v-for="item in passedLists" :key="item.value" :label="item.label" :value="item.value"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="前公司薪资数据:" prop="OldFactorySalary"
                                      :rules="[{ required: true, message: '请填写前公司薪资数据', trigger: 'blur' },]">
                            <el-input type="text" v-model="InterviewForm.OldFactorySalary" @blur="inputMoney('OldFactorySalary')"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <label for="Summarize" class="el-form-item__label"><span style="color: red">*</span>面试总结:</label>
                        <el-input type="textarea" v-model="InterviewForm.Summarize" style="width: 90%"></el-input>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <label for="OldFactoryWelfare" class="el-form-item__label"><span style="color: red">*</span>前公司福利待遇说明:</label>
                        <el-input type="textarea" v-model="InterviewForm.OldFactoryWelfare" style="width: 90%"></el-input>
                    </el-col>
                </el-row>
                <el-row v-if="operation">
                    <el-col :span="24" style="text-align: center">
                        <el-button type="primary" v-on:click="onSubmit('InterviewForm')">提交</el-button>

                    </el-col>
                </el-row>
            </el-form>
        </el-dialog>
        
    </div>
</template>

<script>
    import {
        getInterviewList
    } from "../../api/hr";
    
    export default {
        props: {
            InterviewForm: {},
            interViewId: {
                type: String,
                default: ""
            },
        },
        data() {
            return {
                operation: false,
                interviewVisible: false,
                rules: {
                    Factory: [
                        { required: true, message: "请选择面试公司", trigger: "blur" },
                    ],
                    TalkDate: [{ required: true, message: "请选择日期", trigger: "blur" }],
                    Post: [{ required: true, message: "请选择岗位", trigger: "blur" }],
                    IsPassed: [{ required: true, message: "请选择是否通过", trigger: "blur" }],
                    PostType: [
                        { required: true, message: "请选择岗位类型", trigger: "blur" },
                    ],
                    TalkPersons: [
                        { required: true, message: "请选择面试人员", trigger: "blur" },
                    ],
                    BasicAbility: [
                        { required: true, message: "请输入内容", trigger: "blur" },
                    ],
                    ProfessionalAbility: [
                        { required: true, message: "请输入内容", trigger: "blur" },
                    ],
                    WorkAbility: [
                        { required: true, message: "请输入内容", trigger: "blur" },
                    ],
                    OfficeAbility: [
                        { required: true, message: "请输入内容", trigger: "blur" },
                    ],
                    PotentialAbility: [
                        { required: true, message: "请输入内容", trigger: "blur" },
                    ],
                    DressUp: [{ required: true, message: "请输入内容", trigger: "blur" }],
                    Summarize: [{ required: true, message: "请输入内容", trigger: "blur" }],
                    OldFactoryWelfare: [{ required: true, message: "请输入内容", trigger: "blur" }],

                    Attitude: [{ required: true, message: "请输入内容", trigger: "blur" }],
                    ProfessionalSoftware: [
                        { required: true, message: "请输入内容", trigger: "blur" },
                    ],
                    CommunicateAbility: [
                        { required: true, message: "请输入内容", trigger: "blur" },
                    ],
                },
                OrganizeLists: [],

                positionLists: [
                    {
                        value: "技术",
                        label: "技术",
                    },
                    {
                        value: "后勤",
                        label: "后勤",
                    },
                    {
                        value: "生产",
                        label: "生产",
                    },
                    {
                        value: "管理",
                        label: "管理",
                    },
                    {
                        value: "特聘",
                        label: "特聘",
                    },
                ],
                BasicLists: [
                    {
                        value: "较差",
                        label: "较差",
                    },
                    {
                        value: "一般",
                        label: "一般",
                    },
                    {
                        value: "良好",
                        label: "良好",
                    },
                    {
                        value: "较好",
                        label: "较好",
                    },
                    {
                        value: "优秀",
                        label: "优秀",
                    },
                ],
                passedLists: [
                    {
                        value: "通过",
                        label: "通过",
                    },
                    {
                        value: "未通过",
                        label: "未通过",
                    },
                ],
                matchRateLists: [
                    {
                        value: "较高",
                        label: "较高",
                    },
                    {
                        value: "尚可",
                        label: "尚可",
                    },
                    {
                        value: "较低",
                        label: "较低",
                    },
                    {
                        value: "不符合",
                        label: "不符合",
                    },
                ],
                RecommendationLists: [
                    {
                        value: "强烈建议",
                        label: "强烈建议",
                    },
                    {
                        value: "可安排复试",
                        label: "可安排复试",
                    },
                    {
                        value: "资料存档",
                        label: "资料存档",
                    },
                    {
                        value: "不符合",
                        label: "不符合",
                    },
                ],
            };
        },
        methods: {
            onShowDlg() {
                this.interviewVisible = true;
                if (this.interViewId == "" || this.interViewId == null) {
                    
                }
                else {
                    this.getData(this.interViewId);
                }
            },
            onSubmit(formName) {
                this.$confirm("确定要提交吗？", "提示", {}).then(() => {
                    this.$refs[formName].validate((valid) => {
                        let para = Object.assign({}, this.InterviewForm);
                        if (valid) {
                            UpdateInterview(para).then((res) => {
                                if (res.data.success) {
                                    this.$message({
                                        message: "修改成功",
                                        type: "success",
                                        duration: 5000,
                                    });
                                    this.interviewVisible = false;
                                    this.getData(false);
                                } else {
                                    this.$message({
                                        message: "修改失败",
                                        type: "error",
                                        duration: 5000,
                                    });
                                }
                            });
                        } else {
                            this.$message({
                                message: "请检查必填项",
                                type: "error",
                                duration: 5000,
                            });
                        }
                    });
                });
            },
            inputMoney(name) {
                if (!validate.validateIsMoney(this.InterviewForm[name])) {
                    this.$message({
                        message: "请输入数字类型",
                        type: "error",
                        duration: 5000,
                    });
                    this.InterviewForm[name] = null;
                    return;
                }
            },
            chooseUser() {
                this.addUserVisible = true;
            },

            getData() {
                getInterviewList({ userBaseInfoId: this.InterviewForm.Id }).then((res) => {
                    this.InterviewForm = res.data.response;
                });
            },
        },
        mounted() {
            this.getData();
        }
    }
</script>

<style scoped>
</style>